import React, { Component } from "react";
import PropTypes from "prop-types";
import {Flex} from "theme-ui";
import TimelineSteps from "./timelineSteps";


export default class TimelineStepsList extends Component {
    render() {
        const { steps } = this.props
        return (
            <Flex sx={{ textAlign: 'left', width: '100%', px: 2, flexDirection: ["column"] }}>
                {steps.map((step, i) => {
                    const last = steps.length;
                    return (
                        <TimelineSteps nb={i+1} last={last} key={i} data={step} />
                    )
                })}
            </Flex>
        )
    }
}

TimelineStepsList.propTypes = {
    steps: PropTypes.array.isRequired,
}

