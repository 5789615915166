import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Box, Grid } from "theme-ui";
import Like from "../../../assets/images/like";


export default class TimelineSteps extends Component {
    render() {
        const { data, nb, last } = this.props
        function Circle() {
            if (`${nb}` === `${last}`) {
                return(
                    <Box sx={{ width: '50px', height: '50px', borderRadius: 'full', backgroundColor: 'secondary', color: 'white', textAlign: 'center', position: 'relative' }}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 2,
                            '::before':{content: '""', display: 'block', width: '2px', height: '18px', backgroundColor: 'blue', position: 'absolute', top: '-25px', left: '50%',
                            transform: 'translate(-50%, -50%)'} }}>
                            <Like />
                        </Box>
                    </Box>
                )
            }
            return (
                <Box sx={{ width: '50px', height: '50px', borderRadius: 'full', backgroundColor: 'blue', color: 'white', textAlign: 'center', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 2,
                        '::before':{content: '""', display: 'block', width: '2px', height: '18px', backgroundColor: 'blue', position: 'absolute', top: '-25px', left: '50%',
                        transform: 'translate(-50%, -50%)'} }}>{nb}</Box>
                </Box>
            )
        }
        return (
            <Card sx={{ flex: '1 1 1', px: 2, pt: 3, pb: 1, mb: 2, alignContent: 'center', variant: 'cards.secondary' }} >
                <Grid gap={2} columns={['1fr 5fr', '1fr 5fr']}>
                    <Box>
                        <Circle />
                    </Box>
                    <Box sx={{p:{py: 2, my: 0, lineHeight: '24px'}}} dangerouslySetInnerHTML={{ __html: data.step.html }}/>
                </Grid>
            </Card>
        )
    }
}

TimelineSteps.propTypes = {
    data: PropTypes.object.isRequired,
}