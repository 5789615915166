import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Box, Themed } from "theme-ui";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export default class CardItem extends Component {
    render() {
        const { data } = this.props
        const image = getImage(data.image.localFile.childImageSharp);

        return (
            <Card sx={{ variant: `cards.secondary`, flex: '1 1 25%', px: 2, py: 3, mb: 2 }} >
                <Box>
                    <GatsbyImage image={image} alt="" />
                </Box>
                <Box>
                    <Themed.h5>{data.title.text}</Themed.h5>
                </Box>
                <Box sx={{color: 'white'}}>{data.desc.text}</Box>
            </Card>
        )
    }
}

CardItem.propTypes = {
    data: PropTypes.object.isRequired,
}