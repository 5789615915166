import React from "react";
import {Box, Container, Flex} from "theme-ui"
import PropTypes from "prop-types";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import TimelineStepsList from "../../components/common/TimelineSteps/timelineStepsList";

const TimelineVertical = ({ input }) => {
    const image = getImage(input.primary.image.localFile.childImageSharp);
    return (
        <Box as="section" data-name="timeline" sx={{ width: '100%', textAlign: 'center', my: 0, py: [1, 2, 3]}}>
            <Container >
                <Box sx={{ pt: 0, h2:{fontWeight: 'normal', color: 'blue', fontSize: [3,5], mt: 0, span:{fontWeight: '900'}}}} dangerouslySetInnerHTML={{ __html: input.primary.title1.html }} />
                <Flex sx={{ width: '100%', justifyContent: `space-between`, flexDirection: ['column', 'row', 'row']}}>
                    {/*<Box sx={{ width: ['100%', '45%', '45%'], maxHeight: ['256px', '296px'], textAlign: ['center', 'right', 'right'], pt: 4, px: 4}}>*/}
                    <Box sx={{ width: ['100%', '45%', '45%'], textAlign: ['center', 'right', 'right'], pt: 4, px: 4}}>
                        <GatsbyImage  image={image} alt="" />
                    </Box>
                    <Box sx={{ width: ['100%', '55%', '55%'], pt: 4, p:{ py: 0} }}>
                        <TimelineStepsList steps={input.items} />
                    </Box>
                </Flex>
            </Container>
        </Box>
    )
}

export default TimelineVertical

TimelineVertical.propTypes = {
    input: PropTypes.object.isRequired,
}
