import React from "react"

const Like = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.564" height="23.165" viewBox="0 0 24.564 23.165">
        <g id="prefix__noun_like_939692" transform="translate(-5.436 -7.974)">
            <g id="prefix__Groupe_5" data-name="Groupe 5" transform="translate(5.436 7.974)">
                <path id="prefix__Tracé_481" fill="#fff" d="M15.427 962.768a12.109 12.109 0 0 1-2.231 7.407 1.645 1.645 0 0 0-1.145-.474H7.09a1.669 1.669 0 0 0-1.654 1.654v9.922a1.669 1.669 0 0 0 1.654 1.653h4.96a1.648 1.648 0 0 0 1.189-.517 5.977 5.977 0 0 0 .646.465 4.216 4.216 0 0 0 1.783.543c1.453.151 3.741.038 9.06.06a.835.835 0 0 0 .525-.19 4.948 4.948 0 0 0 1.809-2.678.832.832 0 0 0 .241-.2 4.247 4.247 0 0 0 .982-3.187.829.829 0 0 0 .121-.121 4.123 4.123 0 0 0 .887-3.677 3.2 3.2 0 0 0 .689-2.3 2.733 2.733 0 0 0-1.619-2.188.832.832 0 0 0-.327-.069h-6.787a11.334 11.334 0 0 0-.87-6.881 3.131 3.131 0 0 0-2.2-1.636 2.452 2.452 0 0 0-2.752 2.414zm3.514.043a10.835 10.835 0 0 1 .568 6.735.864.864 0 0 0 .81.982h7.476a1.009 1.009 0 0 1 .543.792 1.578 1.578 0 0 1-.439 1.137H25a.827.827 0 1 0 0 1.654h2.739a2.52 2.52 0 0 1-.431 1.654H25a.827.827 0 1 0 0 1.654h1.64a2.52 2.52 0 0 1-.431 1.654H25a.827.827 0 1 0 0 1.654h.284a3.35 3.35 0 0 1-.913 1.1c-5.018-.016-7.41.066-8.544-.052a2.289 2.289 0 0 1-1.1-.319 11.557 11.557 0 0 1-1.025-.784v-8.38a14.712 14.712 0 0 0 3.376-9.448c.261-1.422 1.495-.832 1.862-.033zM7.09 971.355h4.96c-.019 3.285 0 6.611 0 9.922H7.09z" data-name="Tracé 481" transform="translate(-5.436 -960.336)"/>
            </g>
        </g>
    </svg>
)

export default Like;