import React from "react";
import {Box, Container, Flex} from "theme-ui"
import PropTypes from "prop-types";
import useResponsive from "../../hooks/use-responsive"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import FormCP from "../../components/common/FormCP";
import Wave from "../../assets/images/wave";

const HeroForm = ({ input, phone }) => {
    const { isBigScreen } = useResponsive()
    const image = getImage(input.primary.image.localFile.childImageSharp);
    return (
        <Box as="section" data-name="hero" sx={{ mt: 0, mb: [4,4], py: [ 1, 1,  3], bg:`secondary`, overflow: 'hidden', position: 'relative', zIndex: '0'}}>
            <Container >
                <Flex sx={{ justifyContent: `space-between`, alignItems: `center`, position: 'relative', zIndex: '2'}}>
                    <Box sx={{ maxWidth: `564px`,  flex: '1 1 auto', mx: ['auto', 'auto', '0'] }}>
                        <Box sx={{ textAlign: ['center', 'center', 'left'], h1:{fontSize: 5, color: 'blue', mt: 0, fontWeight: 'normal', lineHeight: ['42px'], mb: 0, span:{ fontWeight: '900'}}}} dangerouslySetInnerHTML={{ __html: input.primary.title1.html}}  />
                        <FormCP label={input.primary.label_button} phone={phone}/>
                    </Box>
                    {isBigScreen && <Box sx={{paddingRight: '100px'}}><GatsbyImage image={image} alt="" /></Box>}
                </Flex>
            </Container>
            <Box sx={{ width: '100%', position: 'absolute', bottom: '-40px', zIndex: '1'}}>
                <Wave />
            </Box>
        </Box>
    )
}

export default HeroForm

HeroForm.propTypes = {
    input: PropTypes.object.isRequired,
}

