import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Box, Grid } from "theme-ui";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

export default class CardCheck extends Component {
    render() {
        const { data } = this.props
        const image = getImage(data.image.localFile.childImageSharp);

        return (
            <Card sx={{ flex: '1 1 25%', px: [0,2], pt: 3, pb: 1, mb: [0,1], variant: 'cards.secondary' }} >
                <Grid gap={2} columns={['1fr 5fr', '1fr 5fr']} sx={{px: 0, mx: 0}}>
                <Box sx={{mx: 0}}>
                    <GatsbyImage image={image} alt="" />
                </Box>
                <Box sx={{p:{py: 0, my: 0, lineHeight: '24px'}}} dangerouslySetInnerHTML={{ __html: data.contenu.html }}/>
                </Grid>
            </Card>
        )
    }
}

CardCheck.propTypes = {
    data: PropTypes.object.isRequired,
}