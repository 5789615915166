import React from "react";
import {Box, Container} from "theme-ui";
import Arrow from "../../../assets/images/arrow";
import {PartnerPhoneButton} from "../PartnerPhone";
import {url} from "../UrlPartner";
import styled from '@emotion/styled'

const ButtonGo = styled.a`
  width: 100%;
  display: block;
  border-radius: 3px;
  padding: 0.8rem 0;
  margin: 0.8rem 0;
  background: #092c4f;
  color: white;
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
`


function FormCP({label}){

    return(
        <Container sx={{ py: [2, 3], mb: [4, 5] , px: 0, maxWidth: '456px', ml: 0}} >
            <Box>
                <ButtonGo href={url} target='_self'  rel="noreferrer">
                    <Box sx={{position: 'absolute', top: '50%', left: '20px', transform: 'translateY(-50%)'}}><Arrow /></Box>
                        {label}
                </ButtonGo>
                <PartnerPhoneButton />
            </Box>
        </Container>
    )
}

export default FormCP;