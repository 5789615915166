import React, { Component } from "react";
import PropTypes from "prop-types";
import {Flex} from "theme-ui";
import CardCheck from "./cardCheck";


export default class CardCheckList extends Component {
    render() {
        const { cards } = this.props
        const { hasborder } = this.props
        return (
            <Flex sx={{ justifyContent: `center`, width: '100%', px: [0,2], flexDirection: ["column", "column", "row", "row" ], border: hasborder === true ? '1px solid' : 'none', pl: hasborder === true ? 3 : 0, borderRadius: 'rounded', borderColor: 'grayLight' }}>
                {cards.map((card, i) => {
                    return (
                        <CardCheck key={i} data={card} />
                    )
                })}
            </Flex>
        )
    }
}

CardCheckList.propTypes = {
    cards: PropTypes.array.isRequired,
    hasborder: PropTypes.bool.isRequired,
}

