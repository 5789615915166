import React from "react";
import { Flex, Box, Themed, Container } from "theme-ui"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

const TextImg = ( { input } ) => {

    const image = getImage(input.primary.image.localFile.childImageSharp);
    const direction = input.primary.img_position;

    return (
        <Box as="section">
            <Container  sx={{ py: [2, 3], bb: [4, 5], position: `relative`, p: { py: 3, lineHeight: '2'}  }} >
                    <Flex sx={{alignItems: `center`, flexDirection: ["column", "column", "column", direction === 'gauche' ? 'row' : 'row-reverse'], justifyContent: 'space-between' }}  >
                        <Box sx={{ flex: '2', px: 1 }}>
                            <GatsbyImage image={image} alt="" />
                        </Box>
                        <Box sx={{ flex: '4' , px: 1}}>
                            <Themed.h2 sx={{variant: `text.heading`}}>{input.primary.ti_title.text}</Themed.h2>
                            <Box sx={{p: { lineHeight: 2, py: 3}, li:{py: 2}  }} dangerouslySetInnerHTML={{ __html: input.primary.text.html }}/>
                        </Box>
                    </Flex>
            </Container>
        </Box>
    )
}

export default TextImg