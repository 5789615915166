import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Hero, HeroForm, Features, LRText, TextImg, Avis, ListCheck, TimelineVertical, Cards} from '../../slices/landing'

export default class SliceZone extends Component {
    render() {
        const { allSlices, phone } = this.props
        const slice = allSlices.map((s) => {
            switch (s.slice_type) {
                // These are the API IDs of the slices
                case 'hero':
                    return <Hero key={s.id} input={s} />
                case 'heroform':
                    return <HeroForm key={s.id} input={s} phone={phone} />
                case 'features':
                    return <Features key={s.id} input={s} />
                case 'lrtext':
                    return <LRText key={s.id} input={s} />
                case 'textimg':
                    return <TextImg key={s.id} input={s} />
                case 'avis':
                    return <Avis key={s.id} input={s} />
                case 'listchecktitle':
                    return <ListCheck key={s.id} input={s} />
                case 'timeline_vertical':
                    return <TimelineVertical key={s.id} input={s} />
                case 'cards':
                    return <Cards key={s.id} input={s} />
                default:
                    return null
            }
        })
        return <>{slice}</>
    }
}

SliceZone.propTypes = {
    allSlices: PropTypes.array.isRequired,
    phone: PropTypes.string.isRequired,
}
