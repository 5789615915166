import React from "react";
import {Box, Container, Flex, Themed} from "theme-ui"
import BasicCardList from "../../components/common/BasicCards/basicCardList";
import {url} from "../../components/common/UrlPartner";

const Cards = ({ input }) => {
    return (
        <Box as="section" data-name="section" sx={{bg: `white`, py: 4}}>
            <Container  sx={{ py: [1, 2, 3], bb: [4, 5], mb: [1,2] }}>
                <Flex sx={{ justifyContent: `space-between` }}>
                    <BasicCardList cards={input.items} />
                </Flex>
                <Flex sx={{ justifyContent: `space-between`, textAlign: "center", flexDirection: `column`, pt: 4}}>
                    <Themed.b as='a' href={url} target={input.primary.link.target} rel="noreferrer">{ input.primary.label }</Themed.b>
                </Flex>
            </Container>
        </Box>
    )
}

export default Cards
