import React, { Component } from "react";
import PropTypes from "prop-types";
import {Flex} from "theme-ui";
import CardItem from "./card";


export default class CardList extends Component {
    render() {
        const { cards } = this.props
        return (
            <Flex sx={{ justifyContent: `space-around`, textAlign: "center", width: '100%', flexDirection: ["column", "column", "row", "row" ] }}>
                {cards.map(card => {
                    return (
//todo index
                        <CardItem key={card.id} data={card} />
                    )
                })}
            </Flex>
        )
    }
}

CardList.propTypes = {
    cards: PropTypes.array.isRequired,
}

