import React from "react";
import Iframe from 'react-iframe'
import {Box, Container, Flex, Themed} from "theme-ui"
import PropTypes from "prop-types";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Hero = ({ input }) => {
    const image = getImage(input.primary.image.localFile.childImageSharp);
    return (
        <Box as="section" data-name="hero" sx={{ mt: 0, py: [8, 8], pb: [6, 7], bg:`hero.bg`, position: 'relative', overflow: 'hidden'}}>
            <Box style={{position: 'absolute', top: 0, left: 0, zIndex: -1}}>
                <GatsbyImage image={image} alt="" sx={{width: '100%', height: 'auto'}}/>
            </Box>
            <Container >
                <Flex sx={{ justifyContent: `space-between`, alignItems: `center`}}>

                    <Box sx={{ maxWidth: `490px`, backgroundColor:'rgba(255,255,255,0.7)', py: 2, px: 4}}>
                        <Themed.h1>{input.primary.title.text }</Themed.h1>
                        <div sx={{ p: {fontSize: 3, lineHeight: 1.5, py: 3} }} dangerouslySetInnerHTML={{ __html: input.primary.paragraph.html }} />
                    </Box>

                    <Box>
                        <Iframe url="https://www.youtube.com/embed/xD6Wn5BoPes"
                                width="560px"
                                height="315px"
                                frameBorder="0"
                                id="heroFrame"
                                display="initial"
                                position="relative"/>
                    </Box>
                </Flex>
            </Container>
        </Box>
    )
}

export default Hero

Hero.propTypes = {
    input: PropTypes.object.isRequired,
}
