import React from 'react';
import PropTypes from 'prop-types'
import {graphql} from "gatsby";
import {Box, ThemeProvider} from 'theme-ui';
import Layout from "../../components/common/layout";
// import { SEO } from '../../components'
import SliceZone from '../../components/landing/slices-zone'
import Header from "../../components/common/Header";
import Footer from "../../components/common/Footer";
import useResponsive from "../../hooks/use-responsive";
import {Helmet} from "react-helmet";
import MobileButton from "../../components/common/MobileButton";

export const query =graphql`
    query ($uid: String!) {
        prismicLanding(uid: { eq: $uid }){
            uid
            data {
                title {
                    text
                    html
                }
                meta_description
                primary
                secondary
                ishomeserve
                heafoo
                phone
                body {
                    ... on PrismicLandingBodyHero {
                        id
                        primary {
                            paragraph {
                                html
                                text
                            }
                            title {
                                html
                                text
                            }
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingBodyHeroform {
                        id
                        primary {
                            title1 {
                                html
                                text
                            }
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 475
                                            quality: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                            label_button
                        }
                        slice_type
                    }
                    ... on PrismicLandingBodyAvis {
                        id
                        primary {
                            avis_title {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingBodyFeatures {
                        id
                        slice_type
                        items {
                            desc {
                                html
                                text
                            }
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 200
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                            title {
                                html
                                text
                            }
                        }
                        primary {
                            intro {
                                html
                                text
                            }
                            tag
                            title {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicLandingBodyLrtext {
                        id
                        primary {
                            text {
                                html
                                text
                            }
                            text_title {
                                html
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingBodyTextimg {
                        id
                        primary {
                            img_position
                            text {
                                html
                                text
                            }
                            ti_title {
                                html
                                text
                            }
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 600
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                        slice_type
                    }
                    ... on PrismicLandingBodyListchecktitle {
                        id
                        slice_type
                        primary {
                            description {
                                html
                                text
                            }
                            title1 {
                                html
                                text
                            }
                            border
                        }
                        items {
                            contenu {
                                html
                                text
                            }
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 26
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicLandingBodyTimelineVertical {
                        id
                        slice_type
                        items {
                            step {
                                html
                                text
                            }
                        }
                        primary {
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 208
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                            title1 {
                                html
                                text
                            }
                        }
                    }
                    ... on PrismicLandingBodyCards {
                        id
                        primary {
                            label
                            link {
                                url
                                link_type
                                target
                            }
                        }
                        slice_type
                        items {
                            content {
                                html
                                text
                            }
                            lien{
                                url
                            }
                            title1
                            image {
                                alt
                                copyright
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 100
                                            placeholder: BLURRED
                                        )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const LandingTemplate = ({ data: { prismicLanding }}, location) => {
    const { data } = prismicLanding;

    const { isBigScreen } = useResponsive();

    function IsHomeserve({children}) {
        const theme = {
            colors: {
                primary: `${prismicLanding.data.primary}`,
                secondary: `${prismicLanding.data.secondary}`,
            },
        }
        if (prismicLanding.data.primary === null) {
            return (
                <Box>
                    {children}
                </Box>
            )
        }
        return (
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        )
    }

    function HasHeader(){
        if (prismicLanding.data.heafoo === true) {
            return (
                <Header phone={prismicLanding.data.phone}/>
            )
        }
        return null
    }

    function HasFooter(){
        if (prismicLanding.data.heafoo === true) {
            return (
                <Footer />
            )
        }
        return null
    }



    return(
        <>
            <Layout>
                <IsHomeserve>
                    {/*<SEO*/}
                    {/*    // title={`${data.title.text} | ${website.titleAlt}`}*/}
                    {/*    title={`${prismicLanding.data.title.text}`}*/}
                    {/*    pathname={location.pathname}*/}
                    {/*    // desc={data.description}*/}
                    {/*    node={prismicLanding}*/}
                    {/*    article*/}
                    {/*/>*/}
                    <Helmet>
                        <title>{prismicLanding.data.title.text}</title>
                        <meta name="description" content={data.meta_description} />
                    </Helmet>
                    <HasHeader/>
                    {!isBigScreen &&
                        <MobileButton />
                    }
                    <SliceZone allSlices={data.body} phone={prismicLanding.data.phone} />
                    <HasFooter />
                </IsHomeserve>

            </Layout>
        </>
    )
};

export default LandingTemplate;

LandingTemplate.propTypes = {
    data: PropTypes.shape({
        prismicLanding: PropTypes.object.isRequired,
        // pages: PropTypes.shape({
        //     nodes: PropTypes.array.isRequired,
        // }),
    }).isRequired,
    location: PropTypes.object.isRequired,
}