import React, {useState} from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {Box} from "theme-ui";
import {url} from "../UrlPartner";
import styled from '@emotion/styled'

const ButtonGo = styled.a`
  background-color: white;
  color: #e7342c; 
  border-radius: 32px;
  display: inline-block;
  padding: 12px 24px;
  font-size: 14px;
`

function MobileButton() {
    const [hideOnScroll, setHideOnScroll] = useState(true)
    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y > prevPos.y
        if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    }, [hideOnScroll], null, false, 1200)

    return(
        <Box sx={{backgroundColor: 'primary', textAlign: 'center',  py: 4, width: '100%', position: "fixed", bottom: 0, zIndex: '999'}} hidden={hideOnScroll}>
            <ButtonGo target='_self' href={url}>Cliquez, c’est dépanné</ButtonGo>
        </Box>
    )
}

export default MobileButton