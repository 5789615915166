import React from 'react'
import { Container, Box } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"
import {affiliateCode} from "../UrlPartner";
import useResponsive from "../../../hooks/use-responsive";
const Footer = () => {
    const { isBigScreen } = useResponsive()
    const mentions = `https://depannage.homeserve.fr/mentions-legales${affiliateCode}`
    const cgv = `https://depannage.homeserve.fr/conditions-generales-de-vente${affiliateCode}`
    const cgu = `https://depannage.homeserve.fr/conditions-generales-d-utilisation${affiliateCode}`
    const cookies = `https://depannage.homeserve.fr/politique-des-cookies${affiliateCode}`
    const policy = `https://depannage.homeserve.fr/politique-de-confidentialite${affiliateCode}`

    return (
        <Box as="footer"
             sx={{
                 fontSize: 0,
                 variant: 'styles.footer',
                 pb: [7, 3]
             }}>
            <Container sx={{ textAlign: 'center'}}>
                <Box sx={{lineHeight: 2, fontSize: 1}}>HomeServe On Demand <br /> 9 rue Anna Marly, 69007 Lyon</Box>
                <Box sx={{my :3}}>
                    <a sx={{ px: 3}} href='https://facebook.com/HomeServeFrance' rel="noreferrer" target='_blank'><StaticImage style={{ width: '32px', height: '32px'}} src="../../../assets/images/facebook.png" alt="facebook" /></a>
                    <a sx={{ px: 3}} href='https://twitter.com/HomeServe_FR' rel="noreferrer" target='_blank'><StaticImage style={{ width: '32px', height: '32px'}} src="../../../assets/images/twitter.png" alt="twitter" /></a>
                    <a sx={{ px: 3}} href='https://www.linkedin.com/company/homeserve-france/' rel="noreferrer" target='_blank'><StaticImage style={{ width: '32px', height: '32px'}} src="../../../assets/images/linkedin.png" alt="linkedin" /></a>
                    <a sx={{ px: 3}} href='https://youtube.com/user/DomeoTV' rel="noreferrer" target='_blank'><StaticImage style={{ width: '32px', height: '32px'}} src="../../../assets/images/youtube.png" alt="youtube" /></a>
                </Box>
                <Box sx={{lineHeight: 2}}>(*) Temps moyen de réponse constaté en 2019</Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: ["row", "row", 'row'],
                        py: 2,
                        fontSize: 0,
                        flexWrap: 'wrap',
                    }}>
                    <Box sx={{mx: 1, my: 1, flex: '1 1 auto'}}>
                        <Box sx={{ mx: 2}} />
                        <a sx={{lineHeight: 2}} rel="noreferrer" href={mentions}>Mentions légales</a>
                    </Box>
                    {isBigScreen && <Box sx={{fontSize: 2 }} >&bull;</Box>}
                    <Box sx={{mx: 1, my: 1,  flex: '1 1 auto'}}>
                        <a sx={{lineHeight: 2}} rel="noreferrer" href={cgv}>Conditions générales de ventes</a>
                    </Box>
                    {isBigScreen && <Box sx={{ fontSize: 2 }} >&bull;</Box>}
                    <Box sx={{mx: 1, my: 1,  flex: '1 1 auto'}}>
                        <a sx={{lineHeight: 2}} rel="noreferrer" href={cgu}>Conditions générales d'utilisation</a>
                    </Box>
                    {isBigScreen && <Box sx={{ fontSize: 2 }} >&bull;</Box>}
                    <Box sx={{mx: 1, my: 1,  flex: '1 1 auto'}}>
                        <a sx={{lineHeight: 2}} rel="noreferrer" href={cookies}>Politique des cookies</a>
                    </Box>
                    {isBigScreen && <Box sx={{ fontSize: 2 }} >&bull;</Box>}
                    <Box sx={{mx: 1, my: 1,  flex: '1 1 auto'}}>
                        <a sx={{lineHeight: 2}} rel="noreferrer" href={policy}>Politique de confidentialité</a>
                    </Box>
                </Box>
                <Box>© HomeServe On Demand 2021</Box>
            </Container>
        </Box>

    )
}
export default Footer;