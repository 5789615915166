import React from "react"

const Wave = ({color}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="375" height="24" viewBox="0 0 375 24">
        <path id="Trace_698" dataname="Trace 698" d="M0,28.318V24.257L375,4.318v24Z" transform="translate(0 -4.318)" fill="#fff"/>
    </svg>

)
export default Wave;

