import React from "react";
import {Box} from "theme-ui"
import PropTypes from "prop-types";
import Avis from "../../components/common/Avis";

const Hero = () => {
    return (
        <Box as="section" data-name="avis" sx={{ mt: 0, py: 3, bg:`hero.bg`, position: 'relative', overflow: 'hidden'}}>
            <Avis />
        </Box>
    )
}

export default Hero

Hero.propTypes = {
    input: PropTypes.object.isRequired,
}
