import React from "react";
import {Box, Container, Flex} from "theme-ui"
import PropTypes from "prop-types";
import CardCheckList from "../../components/common/CardsCheck/cardCheckList";

const ListCheck = ({ input }) => {
    return (
        <Box as="section" data-name="section" sx={{bg: `white`}}>
            <Container  sx={{ py: [0, 1], mt: [2, 3, 0], mb: [0, 1], bb: [4, 5] }}>
                <Flex sx={{ justifyContent: `space-between`, textAlign: ['center', 'left'], flexDirection: `column`}}>
                    <Box as='h2' sx={{color: 'blue', fontSize: [3,5], fontWeight: '900', mx:'auto', textAlign: ['left', 'center']}}>{ input.primary.title1.text }</Box>
                    <Box sx={{ maxWidth: `960px`, ml: [0, 'auto'], mr: [0, 'auto'], textAlign: ['left', 'center'] , p: {fontSize: 1, color: 'blue', lineHeight: 1, py: 1} }} dangerouslySetInnerHTML={{ __html: input.primary.description.html }} />
                </Flex>
                <Flex sx={{ justifyContent: `space-between`, px: 0, mx: 0 }}>
                    <CardCheckList hasborder={input.primary.border} cards={input.items}/>
                </Flex>
            </Container>
        </Box>
    )
}

export default ListCheck

ListCheck.propTypes = {
    input: PropTypes.object.isRequired,
}
