import React from "react"

const Logo = () => (
	<svg version="1.2" baseProfile="tiny" id="Calque_1"
		 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		 viewBox="0 0 209 76.6" overflow="scroll" xmlSpace="preserve" width="108" height="39" >
		<g>
			<path fill="#e7342d" d="M57.6,57.3L57.6,57.3c-0.1,0,0-0.5,0-1V55c0-1.2-0.6-1.8-1.8-1.8h-1.7c-1.2,0-1.8,0.6-1.8,1.8v18.2
		c0,1.2,0.6,1.8,1.8,1.8h1.9c1.2,0,1.8-0.6,1.8-1.8v-8c0-3.6,1.8-7.6,5.6-7.6c2.6,0,2.9,2,2.9,4.4v11.2c0,1.2,0.6,1.8,1.8,1.8H70
		c1.2,0,1.8-0.6,1.8-1.8v-8c0-3.8,1.9-7.6,5.5-7.6c2.5,0,3,1.9,3,4.4v11.2c0,1.2,0.6,1.8,1.8,1.8H84c1.2,0,1.8-0.6,1.8-1.8V61
		c0-5.7-2.6-8.3-7.1-8.3c-3.4,0-6.3,2.2-7.4,4.5h-0.1c-0.9-2.9-3.1-4.5-6.4-4.5C61.3,52.7,58.7,55.2,57.6,57.3 M25.3,73.2V46.5
		c0-1.2-0.6-1.8-1.8-1.8h-1.9c-1.2,0-1.8,0.6-1.8,1.8v10.8c-2.1,1.1-4.4,1.8-6.7,1.8c-2.5,0-4.9-0.7-7.2-2V46.5
		c0-1.2-0.6-1.8-1.7-1.8h-2c-1.2,0-1.8,0.6-1.8,1.8v26.7c0,1.2,0.6,1.8,1.8,1.8h2C5.4,75,6,74.4,6,73.2v-11c2.3,1.1,4.7,1.7,7.2,1.7
		c2.3,0,4.5-0.5,6.7-1.5v10.8c0,1.2,0.6,1.8,1.8,1.8h1.9C24.7,75,25.3,74.4,25.3,73.2 M45.4,64.1c0,4.1-2.9,6.8-6.5,6.8
		c-3.5,0-6.4-2.7-6.4-6.8c0-4,2.9-6.8,6.4-6.8C42.4,57.3,45.4,60.1,45.4,64.1 M38.9,52.7c-6.6,0-12,4.7-12,11.4
		c0,6.7,5.3,11.4,12,11.4c6.6,0,12-4.7,12-11.4C50.9,57.4,45.5,52.7,38.9,52.7 M82.7,32.7c-3.1,3.9-8.2,6.3-13.6,6.3
		c-5.4,0-10.4-2.4-13.6-6.3c-0.2-0.3-0.4-0.7-0.3-1.1c0-0.4,0.2-0.7,0.5-1c0.3-0.2,0.6-0.3,0.9-0.3c0.4,0,0.9,0.2,1.1,0.5
		c2.6,3.3,6.8,5.2,11.3,5.2c4.5,0,8.7-1.9,11.3-5.2c0.3-0.3,0.7-0.5,1.1-0.5c0.3,0,0.6,0.1,0.9,0.3c0.3,0.2,0.5,0.6,0.5,1
		C83,32,82.9,32.4,82.7,32.7 M91.3,23c0-0.7-0.3-1.4-0.8-1.8L70.8,2.1c-0.4-0.4-1.1-0.7-1.7-0.7c-0.7,0-1.3,0.3-1.7,0.7l-7.4,7.2
		l0-1.2c0-1.4-1.1-2.5-2.5-2.5h-2.8c-1.4,0-2.5,1.1-2.5,2.5l0,8.8l-4.5,4.4c-0.4,0.4-0.7,1.1-0.7,1.8c0,1.4,1.1,2.5,2.5,2.5H52
		L52,42c0,1.4,1.1,2.5,2.5,2.5h12.9h16.2c1.4,0,2.5-1.1,2.5-2.5l0-16.6h2.7C90.2,25.5,91.3,24.4,91.3,23 M92.2,62.9
		c-0.3-2.9,1.1-5.3,3.8-6c2.4-0.6,4.6,0.6,5.5,3.5c-1.3-0.2-2.9-0.1-4.7,0.4C94.9,61.3,93.4,62,92.2,62.9 M108.1,70.7
		c0.8-0.8,0.7-1.6,0-2.5l-0.7-0.7c-0.8-0.8-1.2-0.9-2.1-0.1c-0.8,0.9-2.3,2.4-4.3,2.9c-3.7,1-6.8-0.6-8.1-4.2l0,0
		c1.9,0.3,4.4,0.1,7.2-0.7c3.1-0.8,5.3-2,6.4-3.2l0,0c0.5-0.5,0.7-1.2,0.6-1.8c-1.5-5.8-6-9.1-12-7.5c-6.3,1.7-9.5,7.4-7.7,13.9
		c1.6,6,7.3,9.8,14.2,8C104.9,73.9,107,72,108.1,70.7 M115.3,52.7c0-2,1.8-3.4,4.6-3.4c2.3,0,4,0.9,5.2,1.7c0.9,0.5,1.8,0.6,2.4-0.6
		l0.8-1.4c0.5-0.9,0.4-1.7-0.6-2.4c-1.3-0.9-4.2-2.3-7.8-2.3c-5.8,0-10.2,3.5-10.2,8.8c0,9.4,14.1,8.2,14.1,14c0,2-1.7,3.5-4.5,3.5
		c-3,0-5.2-1.5-6.1-2.2c-1.1-0.6-1.8-0.6-2.5,0.4l-0.9,1.4c-0.9,1.2-0.4,1.9,0.3,2.5c1.5,1.2,5,3.1,9.3,3.1c6.4,0,10-4.1,10-9
		C129.4,56.9,115.3,58.3,115.3,52.7 M145.7,61.4c-1.3-0.5-2.8-0.7-4.7-0.6c-1.9,0.1-3.6,0.4-4.9,1c0.4-2.9,2.3-4.9,5-5
		C143.6,56.7,145.5,58.4,145.7,61.4 M151.1,62.6c-0.3-6-3.9-10.2-10.1-9.9c-6.5,0.3-10.8,5.2-10.5,11.9c0.3,6.2,5,11.2,12.2,10.9
		c3.4-0.1,5.9-1.5,7.3-2.5c0.9-0.6,1-1.5,0.5-2.4l-0.5-0.8c-0.6-1-1-1.1-2.1-0.6c-1,0.7-2.8,1.8-4.8,1.9c-3.8,0.1-6.5-2.1-7-5.8l0,0
		c1.7,0.7,4.2,1,7.2,0.9c3.2-0.1,5.6-0.8,6.9-1.7h0C150.8,63.9,151.2,63.3,151.1,62.6 M165.9,56.5v-1.7c0-1.2-0.4-1.8-1.6-1.8
		c-3.1,0-5.5,2.9-6.3,5.5h-0.1c0,0,0.1-0.7,0.1-1.5v-2c0-1.2-0.6-1.8-1.8-1.8h-1.7c-1.2,0-1.7,0.6-1.7,1.8v18.2
		c0,1.2,0.6,1.8,1.7,1.8h1.9c1.2,0,1.8-0.6,1.7-1.8v-7c0-4,1.9-7.9,5.9-7.9C165.2,58.3,165.9,57.7,165.9,56.5 M187.6,55.3
		c0.4-1.2-0.1-2-1.5-2h-2c-1,0-1.7,0.5-2,1.5l-4.1,12c-0.4,1.3-0.8,3.3-0.8,3.3h-0.1c0,0-0.4-2-0.8-3.3l-4.1-12c-0.3-1-1-1.5-2-1.5
		h-2c-1.3,0-1.8,0.8-1.4,2l6.7,18.3c0.3,1,1,1.5,2,1.5h3.4c1,0,1.7-0.4,2-1.5L187.6,55.3z M201.8,61.4c-1.3-0.5-2.8-0.7-4.7-0.6
		c-1.9,0.1-3.6,0.4-4.9,1c0.4-2.9,2.3-4.9,5-5C199.7,56.7,201.6,58.4,201.8,61.4 M207.2,62.6c-0.3-6-3.9-10.2-10.1-9.9
		c-6.5,0.3-10.8,5.2-10.5,11.9c0.3,6.2,5,11.2,12.2,10.9c3.4-0.1,5.9-1.5,7.3-2.5c0.9-0.6,1-1.5,0.5-2.4l-0.5-0.8
		c-0.6-1-1-1.1-2.1-0.6c-1,0.7-2.8,1.8-4.8,1.9c-3.8,0.1-6.5-2.1-7-5.8l0,0c1.7,0.7,4.2,1,7.2,0.9c3.2-0.1,5.6-0.8,6.9-1.7h0
		C206.9,63.9,207.3,63.3,207.2,62.6"/>
		</g>
	</svg>
)

export default Logo;