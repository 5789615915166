import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Box } from "theme-ui";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {data as Pdata} from "../PartnerPhone";

export default class CardItem extends Component {
    render() {
        const { data } = this.props
        const image = getImage(data.image.localFile.childImageSharp);

        const isSSR = typeof window !== "undefined"
        const queryParams = new URLSearchParams(isSSR ? window.location.search: null);
        const partnerId = queryParams.get('affiliationCode');
        const selectedPartner = Pdata.find(partner => partner.affiliationCode.includes(partnerId));
        const url = (selectedPartner !== undefined ? `${data.lien.url}?affiliationCode=${partnerId}` : `${data.lien.url}` )

        return (
            <Card sx={{ variant: `cards.primary`, flex: ['1 1 48%','1 1 25%'], px: 2, py: 2, mb: 2, borderRadius: 'rounded', maxWidth: ['47%', '22%']}} >
                <a href={url} target={data.lien.target} rel="noreferrer">
                    <Box>
                        <GatsbyImage image={image} alt="" />
                    </Box>
                    <Box sx={{fontSize: 0, color: 'blue', py: 2}}>
                        {data.title1}
                    </Box>
                    <Box>{data.content.text}</Box>
                </a>
            </Card>
        )
    }
}

CardItem.propTypes = {
    data: PropTypes.object.isRequired,
}