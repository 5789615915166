import {Box, Container, Themed} from "theme-ui"
import React from "react";

const LRText = ( {input} ) => {
    return (
        <Box as="section">
            <Container  sx={{ py: [2, 3], bb: [4, 5], position: `relative`, p: { py: 3, lineHeight: '2'}  }} >
                <Box sx={{ maxWidth: `860px` }}>
                    <Themed.h2>{input.primary.text_title.text}</Themed.h2>
                    <Box dangerouslySetInnerHTML={{ __html: input.primary.text.html }} />
                </Box>
            </Container>
        </Box>
    )
}

export default LRText