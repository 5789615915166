import React, {useRef} from "react";
import IframeResizer from 'iframe-resizer-react'
import {Container} from "theme-ui";
import { useMediaQuery } from "react-responsive"

function Avis(){
    const isMobile = useMediaQuery({
        maxWidth: `767px`,
    })
    const iframeRef = useRef(null)

    function IsMobileDevice() {
        if (isMobile === true) {
            return(
            <IframeResizer
                src="https://cl.avis-verifies.com/fr/cache/e/5/7/e57d3166-b99f-01d4-e122-5fb1008776f9/widget4/e57d3166-b99f-01d4-e122-5fb1008776f9horizontal_index.html#/avis"
                width="100%"
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                height="320px"
                id="avis"
                styles={{borderWidth: '0'}}
                frameBorder="0"
                display="initial"
                position="relative"
                scrolling='no'
            />
            )
        }
        return (
            <IframeResizer
                src="https://cl.avis-verifies.com/fr/cache/e/5/7/e57d3166-b99f-01d4-e122-5fb1008776f9/widget4/e57d3166-b99f-01d4-e122-5fb1008776f9horizontal_index.html#/avis"
                width="100%"
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                minHeight="100%"
                id="avis"
                styles={{borderWidth: '0'}}
                frameBorder="0"
                display="initial"
                position="relative"/>
        )
    }

    return(
        <Container sx={{my: 0, py: 0}} >
            <IsMobileDevice/>
        </Container>
    )
}

export default Avis;