import React, { Component } from "react";
import PropTypes from "prop-types";
import {Flex} from "theme-ui";
import CardItem from "./basicCard";


export default class BasicCardList extends Component {
    render() {
        const { cards } = this.props
        return (
            <Flex sx={{ justifyContent: `space-between`, textAlign: "center", width: '100%', flexDirection: ["row", "row"], flexWrap: 'wrap' }}>
                {cards.map((card, i) => {
                    return (
//todo index
                        <CardItem key={i} data={card} />
                    )
                })}
            </Flex>
        )
    }
}

BasicCardList.propTypes = {
    cards: PropTypes.array.isRequired,
}

