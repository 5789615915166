import React from 'react';
import Headroom from 'react-headroom';
import {Container, Flex, Box} from "theme-ui";
import styled from '@emotion/styled'
import Logo from "../../../assets/images/logo";
import {PartnerPhoneHeader} from "../PartnerPhone";

const StyledHeader = styled(Headroom)`
  .headroom {
  top: 0;
  left: 0;
  right: 0;
  zIndex: 1;
  background-color: #f5f5f5;
}
.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
`

const Header = () => {
    return(
        <StyledHeader >
            <Container as="header" py='1'>
                <Flex sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
                    <a
                        aria-label="Link to Homeserve"
                        sx={{ width: '108px', height: '39px', mr: [2, 6], color:'primary', "&:hover,&:focus": { color: `primary`, boxShadow: `none` } }}
                        href="https://depannage.homeserve.fr"
                    >
                        <Logo styles={{width: '108px', height: '39px'}}/>
                    </a>
                    <Box sx={{fontWeight: '900', display: 'flex', alignItems: 'center'}}>
                        <PartnerPhoneHeader />
                    </Box>
                </Flex>
            </Container>
        </StyledHeader>
    )
}

export default Header

