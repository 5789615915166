import React from "react";
import {Box, Themed} from "theme-ui";
import Phone from "../../../assets/images/phone";

export const data = [
    {
        'partnerId': 'Smartloc',
        'affiliationCode': 'ae24ff50-6446-11ea-a917-2f990b2efaf9',
        'phoneNumber': '01 70 96 50 77'
    },
    {
        'partnerId': 'Bailfacile.fr',
        'affiliationCode': '069fdb40-6830-11ea-bf77-6991428602b5',
        'phoneNumber': '01 70 96 50 76'
    },
    {
        'partnerId': 'MaPlateformePro',
        'affiliationCode': '72517030-71d3-11eb-a8b6-99d057ae2910',
        'phoneNumber': '01 70 96 50 70'
    },
    {
        'partnerId': 'Maison.fr',
        'affiliationCode': 'ace99740-71d3-11eb-a8b6-99d057ae2910',
        'phoneNumber': '01 70 96 50 85'
    },
    {
        'partnerId': 'GoogleAds',
        'affiliationCode': '23ac7d10-7847-11eb-b359-11d0567f6bf9',
        'phoneNumber': '01 70 96 50 97'
    },
    {
        'partnerId': 'FacebookAds',
        'affiliationCode': '8d830f20-8570-11eb-9c7c-9bab4580ee38',
        'phoneNumber': '01 70 95 26 26'
    },
    {
        'partnerId': 'LeBonCoin',
        'affiliationCode': '007237e0-b6ea-11eb-8635-cf57e453eadd',
        'phoneNumber': '01 70 96 50 63'
    },
    {
        'partnerId': 'MicrosoftAds',
        'affiliationCode': '62afe8e0-d58e-11eb-990d-43aae9b9594e',
        'phoneNumber': '01 70 95 26 03'
    },
    {
        'partnerId': 'Leggett',
        'affiliationCode': '00c2e940-d591-11eb-a2a1-f9e428bc354b',
        'phoneNumber': '01 70 95 26 02'
    },
    {
        'partnerId': 'Habitat & Humanism',
        'affiliationCode': '4806aa30-db13-11eb-b2b3-058fba6231a6',
        'phoneNumber': '01 70 95 26 01'
    }
];
const isSSR = typeof window !== "undefined"
const queryParams = new URLSearchParams(isSSR ? window.location.search: null);
const partnerId = queryParams.get('affiliationCode');


export function PartnerPhoneHeader() {
    const selectedPartner = data.find(partner => partner.affiliationCode.includes(partnerId));
    const phoneNumber = (selectedPartner !== undefined ? selectedPartner.phoneNumber : '01 70 82 17 82')

    return (
        <Box sx={{fontWeight: '900', display: 'flex', alignItems: 'center'}}>
            <Phone/>
            <Box sx={{px: 2}}/>
            <span>
                <Themed.a href={`tel:${phoneNumber}`}>{phoneNumber}</Themed.a>
            </span>
        </Box>
    )
}

export function PartnerPhoneButton() {
    const selectedPartner = data.find(partner => partner.affiliationCode.includes(partnerId));
    const phoneNumber = (selectedPartner !== undefined ? selectedPartner.phoneNumber : '01 70 82 17 82')
    return (
        <Themed.a as='a' href={`tel:${phoneNumber}`}>
            <Box sx={{width: '100%', py: '1.25rem', textAlign: 'center', backgroundColor: 'white', color: 'blue'}}>
                Ou contactez-nous au {phoneNumber}
            </Box>
        </Themed.a>
    )
}

