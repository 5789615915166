import React from "react";
import {Box, Container, Flex, Themed} from "theme-ui"
import PropTypes from "prop-types";
import CardList from "../../components/common/Cards/cardList";

const Features = ({ input }) => {
    return (
        <Box as="section" data-name="section" sx={{bg: `white`}}>
            <Container  sx={{ py: [2, 3], bb: [4, 5] }}>
                <Flex sx={{ justifyContent: `space-between`, textAlign: "center", flexDirection: `column`}}>
                    <Box sx={{textTransform: `uppercase`}}>{ input.primary.tag }</Box>
                    <Themed.h2 sx={{variant: 'text.heading', maxWidth: `760px`, mx:'auto'}}>{ input.primary.title.text }</Themed.h2>
                    <Box sx={{ maxWidth: `960px`, ml: `auto`, mr: `auto`, p: {fontSize: 2, lineHeight: 1, py: 1 } }} dangerouslySetInnerHTML={{ __html: input.primary.intro.html }} />
                </Flex>
                <Flex sx={{ justifyContent: `space-between` }}>
                    <CardList cards={input.items} />
                </Flex>
            </Container>
        </Box>
    )
}

export default Features

Features.propTypes = {
    input: PropTypes.object.isRequired,
}
